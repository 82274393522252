<template>
  <form @submit.prevent="onSubmit" novalidate>
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="mb-3"
        placeholder="First Name"
        v-model="v$.first_name.$model"
        :icon="faUser"
        :errors="v$?.first_name?.$errors[0]?.$message"
      />
      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="mb-3"
        placeholder="Last Name"
        v-model="v$.last_name.$model"
        :icon="faUser"
        :errors="v$?.last_name?.$errors[0]?.$message"
      />

      </div>
      <div class="col-span-2 sm:col-span-1">
              <TextInput
        class="mb-3"
        placeholder="Address Line 1"
        v-model="v$.address_line_1.$model"
        :icon="faMapMarkerAlt"
        :errors="v$?.address_line_1?.$errors[0]?.$message"
      />
      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="mb-3"
        placeholder="Address Line 2"
        v-model="v$.address_line_2.$model"
        :icon="faMapMarkerAlt"
        :errors="v$?.address_line_2?.$errors[0]?.$message"
      />
      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="mb-3"
        placeholder="City"
        v-model="v$.city.$model"
        :icon="faMapMarkerAlt"
        :errors="v$?.city?.$errors[0]?.$message"
      />
      </div>
      <div class="col-span-2 sm:col-span-1">
      <div class="relative py-1">
        <FontAwesomeIcon class="absolute z-10 text-xl top-5 left-2 text-custom-green" :icon="faGlobeAmericas" />
      <select
        class="block w-full py-3 pl-10 mb-3 text-lg border-0 shadow focus:border-b-1 focus:border-custom-green focus:ring-0 focus:shadow-lg ring-2 ring-gray-100"
        placeholder="Province"
        v-model="v$.province.$model"

        :errors="v$?.province?.$errors[0]?.$message"
      >
      <option value="" selected disabled class="text-gray-300">Province</option>
      <option v-for="province in provinces" :value="province" :key="province "> {{ province  }} </option>
      </select>
      </div>

      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="mb-3 uppercase"
        placeholder="Postal Code"
        v-model="v$.postal_code.$model"
        inputClass="uppercase"
        :icon="faMapMarkedAlt"
        :errors="v$?.postal_code?.$errors[0]?.$message"
      />

      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="mb-3"
        type="tel"
        placeholder="Phone number"
        v-model="v$.phone_number.$model"
        onkeydown="return ( event.ctrlKey || event.altKey
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9)
                    || (event.keyCode>34 && event.keyCode<40)
                    || (event.keyCode==46) )"
        :icon="faPhoneAlt"
        :errors="v$?.phone_number?.$errors[0]?.$message"
      />

      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        class="w-full col-span-2 mb-3"
        placeholder="Enter Email"
        v-model="v$.email.$model"
        :icon="faEnvelope"
        :errors="v$?.email?.$errors[0]?.$message"
      />
      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        type="password"
        v-model="v$.password.$model"
        placeholder="Enter Password"
        :icon="faLock"
        :errors="v$?.password?.$errors[0]?.$message"
      />
      <p class="text-sm text-red-500" v-if="passError">{{ passError }}</p>

      </div>
      <div class="col-span-2 sm:col-span-1">
      <TextInput
        type="password"
        class="mb-5"
        v-model="v$.confirm_password.$model"
        placeholder="Confirm Password"
        :icon="faLock"
        :errors="v$?.confirm_password?.$errors[0]?.$message"
      />
      </div>
    </div>
    <SubmitButton class="px-20 mx-auto mb-5" :isLoading="isLoading"
      >Register</SubmitButton
    >
    <div class="relative w-full">
      <div
        v-if="errors"
        class="absolute w-full p-4 font-medium text-red-800 rounded-md bg-red-50"
      >
        {{ errors }}
      </div>
    </div>
  </form>
</template>
<script>
import { faEnvelope, faGlobeAmericas, faLock, faMapMarkedAlt, faMapMarkerAlt, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { reactive, ref } from '@vue/reactivity'
import { useVuelidate } from '@vuelidate/core'
import { email, helpers, minLength, required } from '@vuelidate/validators'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SubmitButton from '../SubmitButton.vue'
import TextInput from '../TextInput.vue'

export default {
  components: { TextInput, SubmitButton, FontAwesomeIcon },
  setup () {
    const isLoading = ref(false)
    const passError = ref('')
    const errors = ref('')
    const store = useStore()
    const router = useRouter()

    const provinces = ref([
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Nova Scotia',
      'Northwest Territories',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Québec',
      'Saskatchewan',
      'Yukon'
    ])
    const state = reactive({
      first_name: '',
      last_name: '',
      address_line_1: '',
      address_line_2: '',
      phone_number: '',
      city: '',
      postal_code: '',
      province: '',
      email: '',
      password: '',
      confirm_password: ''
    })
    const postalCodeFormat = (value) => {
      if (typeof value === 'undefined' || value === null || value === '') {
        return true
      }
      // helpers.regex('postalCodeFormat', /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i)
      return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i.test(
        value
      )
    }

    const rules = {
      first_name: {
        required: helpers.withMessage('First name is required.', required)
      },
      last_name: {
        required: helpers.withMessage('Last name is required.', required)
      },
      address_line_1: {
        required: helpers.withMessage('Address is required.', required)
      },
      address_line_2: {
        // required: helpers.withMessage('Address is required.', required)
      },
      phone_number: {
        required: helpers.withMessage('Phone is required.', required)
      },
      city: { required: helpers.withMessage('City is required.', required) },
      postal_code: {
        required: helpers.withMessage('Postal code is required.', required),
        postalCodeFormat: helpers.withMessage(
          'Postal code is invalid. Ex. A1A 1A1',
          postalCodeFormat
        )
      },
      province: {
        required: helpers.withMessage('Province is required.', required)
      },
      email: {
        required: helpers.withMessage('Email field is required', required),
        email: helpers.withMessage(
          'Email should be a valid email address ',
          email
        )
      },
      password: {
        required: helpers.withMessage('Password field is required', required),
        minLength: minLength(6)
      },
      confirm_password: {
        required: helpers.withMessage(
          'Confirm Password field is required',
          required
        ),
        minLength: minLength(6)
        // sameAsPassword: sameAs(state.password)
      }
    }
    const v$ = useVuelidate(rules, state)

    const onSubmit = () => {
      passError.value = ''
      if (state.password !== state.confirm_password) {
        passError.value = 'Password and confirm  password does not match'
        return
      }
      v$.value.$touch()

      if (v$.value.$invalid) return

      isLoading.value = true
      errors.value = null
      store
        .dispatch('auth/SignUp', state)
        .then(
          (data) => {
            console.log(data)
            router.push({
              name: 'Success'
            })
          },
          (error) => {
            if (error.response.status === 500) {
              errors.value = 'Something went wrong please try again.'
            } else if (error.response.status === 400) {
              console.log('error 400', error)
              errors.value = 'Invalid username or password'
            } else {
              errors.value =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error ||
                error.toString()
            }
          }
        )
        .then(function () {
          isLoading.value = false
        })
    }
    return {
      onSubmit,
      v$,
      faEnvelope,

      faLock,
      faMapMarkedAlt,
      faPhoneAlt,
      state,
      faMapMarkerAlt,
      faGlobeAmericas,
      errors,
      passError,
      faUser,
      provinces,
      isLoading
    }
  }
}
</script>
